import React, { useEffect, useRef, useState } from "react";

// Components
import RommelmarktVideo from "resources/PromoForSite.mp4";
import RommelmarktSnap from "resources/promo_snap.png";
import ProgrammaVideo from "resources/programma.mp4";
import ProgrammaSnap from "resources/programma_snap.png";

// Helpers
import { useNavigate } from "react-router-dom";

// Styling
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-flow: row;
  width: 100vw;
  height: 100vh;
`;

const Programma = styled.div`
  width: ${({ hovered }) => {
    return hovered ? 70 : 30;
  }}%;
  transition: 1s ease-out;
  position: relative;
  overflow: hidden;
  cursor: none;
`;

const Rommelmarkt = styled.div`
  width: ${({ hovered }) => {
    return hovered ? 70 : 30;
  }}%;
  transition: 1s ease-out;
  position: relative;
  overflow: hidden;
  cursor: none;
`;

const StyledVideo = styled.video`
  position: absolute;
  top: -100%;
  bottom: -100%;
  left: -100%;
  right: -100%;
  margin: auto;
  height: 100%;
  max-width: none;
  transition: width 1s ease, height 1s ease;
`;

const Label = styled.div`
  font-size: 2.5rem;
  position: relative;
  width: fit-content;
  left: 50%;
  bottom: -80%;
  transform: translateX(-50%);
  padding: 10px 50px;
  color: white;
  text-shadow: 1px 1px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000,
    -1px 1px 0 #000, 1px 1px 0 #000;
  font-family: "Trebuchet MS", "Courier New", monospace;
  z-index: 10;
`;

const Button = styled.div`
  border: 1px solid white;
  position: absolute;
  user-select: none;
  font-size: 2rem;
  border-radius: 20px;
  color: white;
  padding: 10px 30px;
  pointer-events: none;
  background: rgba(44, 74, 118, 0.75);
  text-shadow: 1px 1px 2px black;
  z-index: 10;
`;

const StyledImage = styled.img`
  position: absolute;
  top: -100%;
  bottom: -100%;
  left: -100%;
  right: -100%;
  margin: auto;
  height: 100%;
  max-width: none;
  transition: width 1s ease, height 1s ease;
  z-index: 5;
`;

const HomePage = () => {
  const [hovered, setHovered] = useState("programma");
  const [buttonText, setButtonText] = useState("");

  const [programVideoLoaded, setProgramVideoLoaded] = useState(false);
  const [rommelmarktVideoLoaded, setRommelmarktVideoLoaded] = useState(false);

  const navigate = useNavigate();

  const programmaVideoRef = useRef();
  const rommelmarktVideoRef = useRef();
  const buttonRef = useRef();

  const moveCursor = (e) => {
    const mouseY = e.clientY;
    const mouseX = e.clientX;
    if (buttonRef.current) {
      buttonRef.current.style.transform = `translate(calc(${mouseX}px - 50%), calc(${mouseY}px - 50%))`;
    }
  };

  useEffect(() => {
    if (buttonRef.current) {
      window.addEventListener("mousemove", moveCursor);
    }
  }, [buttonRef]);

  useEffect(() => {
    if (rommelmarktVideoRef.current && hovered === "rommelmarkt") {
      rommelmarktVideoRef.current.playbackRate = 0.9;
      rommelmarktVideoRef.current.play();
      programmaVideoRef.current.pause();
    } else if (programmaVideoRef.current && hovered === "programma") {
      programmaVideoRef.current.playbackRate = 0.9;
      programmaVideoRef.current.play();
      rommelmarktVideoRef.current.pause();
    }
  }, [rommelmarktVideoRef, programmaVideoRef, hovered]);
  return (
    <Wrapper>
      <Programma
        onMouseEnter={() => {
          setHovered("programma");
          setButtonText("Bekijk het programma ->");
        }}
        hovered={hovered === "programma"}
        onClick={() => {
          window.removeEventListener("mousemove", moveCursor);
          navigate("program");
        }}
      >
        {!programVideoLoaded && <StyledImage src={ProgrammaSnap} />}
        <StyledVideo
          hovered={true}
          onContextMenu={(e) => {
            e.preventDefault();
          }}
          onCanPlay={() => {
            setProgramVideoLoaded(true);
          }}
          ref={programmaVideoRef}
          src={ProgrammaVideo}
          type="video/mp4"
          muted
          autoplay
          loop
        />
        <Label>Programma</Label>
      </Programma>
      <Rommelmarkt
        onMouseEnter={() => {
          setHovered("rommelmarkt");
          setButtonText("Schrijf je in ->");
        }}
        hovered={hovered === "rommelmarkt"}
        onClick={() => {
          window.removeEventListener("mousemove", moveCursor);
          navigate("market");
        }}
      >
        {!rommelmarktVideoLoaded && <StyledImage src={RommelmarktSnap} />}
        <StyledVideo
          onContextMenu={(e) => {
            e.preventDefault();
          }}
          onCanPlay={() => {
            setRommelmarktVideoLoaded(true);
          }}
          ref={rommelmarktVideoRef}
          src={RommelmarktVideo}
          type="video/mp4"
          muted
          autoplay
          loop
        />
        <Label>Rommelmarkt</Label>
      </Rommelmarkt>
      <Button ref={buttonRef}>{buttonText}</Button>
    </Wrapper>
  );
};

export default HomePage;
