export const isPersonFromNeighbourhood = (user) => {
  switch (user.Straat) {
    case "Blekerij":
    case "Broeders van Liefdestraat":
    case "Burggravenstraat":
    case "Dullaert":
    case "Euerardstraat":
    case "Ganzepoel":
    case "Jan Breydelstraat":
    case "Kaaistraat":
    case "Krugercenter":
    case "Pieter De Coninckstraat":
    case "Raashekke":
    case "Schuttershof":
    case "Visstraat":
    case "Zuidmoerstraat":
      if (user.Gemeente === "Eeklo") {
        return true;
      }
      return false;
    default:
      return false;
  }
};

export const enkelBeschikbarePlaatsen = (plaatsen) => {
  const nietBeschikbareInZuidmoer = [
    "862",
    "864",
    "866",
    "868",
    "870",
    "872",
    "874",
    "876",
    "878",
    "899Z",
    "899Y",
    "899X",
    "899W",
    "899V",
    "899U",
    "899T",
    "899S",
    "899R",
    "899Q",
    "899P",
    "899O",
    "899N",
    "899M",
    "899L",
    "899K",
    "899J",
    "899I",
    "899H",
    "899G",
    "899F",
    "899E",
    "899D",
    "899C",
    "899B",
    "899A",
    "899",
    "898D",
    "898C",
    "898B",
    "898A",
    "898",
    "897",
    "896",
    "895",
    "894",
    "893",
    "892",
    "891",
    "890",
    "889",
    "888",
    "887",
    "886",
    "885",
    "884",
    "883",
    "882",
    "881",
    "880",
  ];

  const allePlaatsen = plaatsen.filter((x) => {
    return (
      x.Straat !== "Schuttershof" &&
      x.Straat !== "Jan Breydelstraat" &&
      x.Straat !== "Pieter De Coninckstraat" &&
      !nietBeschikbareInZuidmoer.includes(x.Standplaats)
    );
  });

  return allePlaatsen;
};
