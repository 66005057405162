import React from "react";

// Affiches
// import Vrijdag from "resources/affiche_vrijdag.jpg";
import Zaterdag from "resources/affiche_zaterdag.png";
import Zondag from "resources/affiche_zondag.png";
// import Maandag from "resources/affiche_maandag.jpg";

// Styling
import styled from "styled-components";

const Title = styled.h1``;

const Description = styled.h2`
  opacity: ${({ hide }) => (hide ? "0" : "1")};

  @media (max-width: 768px) {
    display: ${({ hide }) => (hide ? "none" : "visible")};
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-flow: row;
  height: 100%;
  margin-left: 100px;
  margin-right: 100px;

  @media (max-width: 768px) {
    flex-flow: column;
    align-items: center;
    justify-content: center;
  }
`;

const VerticalWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-flow: column;
  width: 100%;
  height: 75%;
`;

const Affiche = styled.img`
  width: auto;
  height: 100%;
`;

const ProgramPage = () => {
  return (
    <Wrapper>
      {/* <VerticalWrapper>
        <Title>Vrijdag 29 september</Title>
        <Description hide={true}>{"."}</Description>
        <Affiche src={Vrijdag} />
      </VerticalWrapper>*/}
      <VerticalWrapper>
        <Title>Zaterdag 30 september</Title>
        <Description>
          Bestel je tickets{" "}
          <a
            href="https://www.your-tickets.be/events/AmbianceoptKaaiken2024"
            target="_blank"
            rel="noopener noreferrer"
          >
            hier
          </a>
        </Description>
        <Affiche
          src={Zaterdag}
          onClick={() => {
            window.open(
              "https://www.your-tickets.be/events/AmbianceoptKaaiken2024",
              "_blacnk"
            );
          }}
        />
      </VerticalWrapper>
      <VerticalWrapper>
        <Title>Zondag 1 oktober</Title>
        <Description hide={true}>{"."}</Description>
        <Affiche src={Zondag} />
      </VerticalWrapper>
      {/* <VerticalWrapper>
        <Title>Maandag 2 oktober</Title>
        <Description hide={true}>{"."}</Description>
        <Affiche src={Maandag} />
      </VerticalWrapper> */}
    </Wrapper>
  );
};

export default ProgramPage;
