import React, { useState, useEffect } from "react";

import { GetAllStandplaatsen, GetUniqueId, StartPaymentInDB } from "api/calls";
import { useAuthState } from "stores/AuthStore";
import configs from "config.json";
import sha1 from "sha1";
import { getLanguage } from "localization/localizer";
import localizer from "localization/localizer";
import { useNavigate } from "react-router-dom";

// Components
import { GoogleMap, useLoadScript } from "@react-google-maps/api";
import Button from "components/Button";

// Styling
import styled from "styled-components";
import { motion } from "framer-motion";
import StandplaatsMarker from "components/StandplaatsMarker";
import { enkelBeschikbarePlaatsen } from "helpers";

const Wrapper = styled(motion.div)`
  height: 100%;
  margin: 30px;
  overflow: auto;
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  padding: 5px;
  font-size: 28px;
  margin-top: 15px;
`;

const HorizontalWrapper = styled.div`
  display: flex;
  flex-flow: row;
  width: 100%;
  height: 90%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: center;
`;

const StepIndicator = styled.div`
  flex-basis: 35%;
  border-right: 1px solid black;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const StepDescription = styled.div`
  margin: 10px;
`;
const Line = styled.div`
  margin: 5px;
`;

const Divider = styled.div`
  height: 1px;
  background-color: black;
  width: 90%;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
`;

const SelectedPlacesWrapper = styled.div`
  display: flex;
  flex-flow: column;
  height: 50%;
  align-items: center;
`;
const SelectedTitle = styled.div`
  margin-top: 35px;
  font-size: 20px;
`;

const SelectedPlaces = styled.div`
  font-size: 24px;
  margin-top: 50px;
  font-weight: bold;
`;

const CancelButton = styled(Button)`
  height: 2.5em;
  width: 30%;
  margin-right: 25px;
  margin-top: auto;
`;

const ConfirmButton = styled(Button)`
  height: 2.5em;
  width: 30%;
  margin-top: auto;
`;

const MapWrapper = styled(motion.div)`
  flex-basis: 65%;
  flex-grow: 1;
  height: 100%;
`;

const Error = styled.div`
  font-size: 1rem;
  margin-bottom: 0.5em;
  font-weight: bold;
  width: 80%;
  display: flex;
  align-items: center;
  margin: auto;
  flex-flow: column;
`;

const ToLoginButton = styled.div`
  margin-top: 10px;
  text-decoration: underline;
  cursor: pointer;
`;

// const SubTitle = styled.div`
//   font-size: 1.5rem;
// `;

// const Text = styled.div`
//   font-size: 1.2rem;
//   max-width: 80%;
//   text-align: center;
//   margin-bottom: 0.75em;
// `;

// const SmallText = styled.div`
//   font-size: 1rem;
//   max-width: 80%;
//   text-align: center;
//   margin-bottom: 5px;
// `;

// const Message = styled.div`
//   display: flex;
//   flex-flow: column;
//   justify-content: center;
//   align-items: center;
// `;
let pricePP = 12;

const Rommelmarkt = () => {
  const navigate = useNavigate();

  const loaderId = "loader-maps-kaaiken";
  const mapId = "maps-kaaiken";
  const googleMapsLibraries = ["drawing", "visualization", "places"];

  const [center, setCenter] = useState({
    lat: 51.180523,
    lng: 3.568949,
  });

  const { AuthState } = useAuthState();
  const { token, user, street } = AuthState;

  const [standplaatsen, setStandplaatsen] = useState([]);
  const [selectedPlaatsen, setSelectedPlaatsen] = useState([]);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: configs.googleMapsApiKey,
    id: loaderId,
    version: "weekly",
    preventGoogleFontsLoading: true,
    googleMapsLibraries,
  });

  const [mapInstance, setMapInstance] = useState();
  const [zoomLevel, setZoomLevel] = useState(17);

  const [currentStep, setCurrentStep] = useState(1);

  // Payment info
  const [orderId, setOrderId] = useState();
  const [amount, setAmount] = useState();
  const [orderDescription, setOrderDescription] = useState();
  const [hash, setHash] = useState();
  const [name, setName] = useState();

  const today = new Date();

  let phase = 0;
  if (today.getMonth() + 1 >= 11 && today.getMonth() <= 12) {
    phase = 1;
  } else if (today.getMonth() + 1 >= 1 && today.getMonth() + 1 <= 3) {
    phase = 2;
  } else {
    phase = 3;
  }

  const onMapLoad = (instance) => {
    setMapInstance(instance);
  };

  const initPayment = async () => {
    const { data } = await GetUniqueId(token);
    setOrderId(data);

    const price = selectedPlaatsen.length * pricePP * 100;
    setAmount(price);

    const description = `Standplaats${
      selectedPlaatsen.length > 1 ? "en" : ""
    } ${selectedPlaatsen.map(
      (plaats) => plaats.Standplaats
    )} voor de rommelmarkt van de Kaaifeesten`;
    setOrderDescription(description);

    const sha = sha1(`1362980101${data}${price}${description}C067600410`);
    setHash(sha);

    const n = `${user.Voornaam} ${user.Naam}`;
    setName(n);
    setCurrentStep(2);
  };

  const startPayment = () => {
    StartPaymentInDB(token, {
      orderId: orderId,
      StandhouderID: user.StandhouderID,
      Standplaatsen: selectedPlaatsen.map((plaats) => plaats.Standplaats),
      Naam: user.Naam,
      Voornaam: user.Voornaam,
      Bedrag: amount / 100.0,
    }).then((result) => {
      const { data } = result;

      if (data && result.status === 200) {
        document.getElementById("paymentForm").submit();
      }
    });
  };

  useEffect(() => {
    if (mapInstance && user) {
      GetAllStandplaatsen().then((result) => {
        if (result.data) {
          setTimeout(() => {
            const allePlaatsen = enkelBeschikbarePlaatsen(result.data);

            const tod = new Date();
            if (tod.getMonth() + 1 >= 1 && tod.getMonth() + 1 <= 3) {
              if (user.placesLastYear?.length > 0) {
                setStandplaatsen(user.placesLastYear);
                const cent = JSON.parse(user.placesLastYear[0]?.path);
                if (cent) {
                  setCenter({
                    lat: cent[0].lat,
                    lng: cent[0].lng,
                  });
                  setZoomLevel(21);
                }
              } else if (user.isPersonFromNeighbourhood) {
                setStandplaatsen(allePlaatsen);
              }
            } else {
              setStandplaatsen(allePlaatsen);
            }
          }, 1000);
        }
      });
    }
  }, [mapInstance, user]);

  useEffect(() => {
    if (mapInstance && street) {
      setZoomLevel(20);
      switch (street) {
        case "Blekerij":
          setCenter({
            lat: 51.178149,
            lng: 3.562165,
          });
          break;
        case "Broeders van Liefdestraat":
          setCenter({
            lat: 51.179404,
            lng: 3.562489,
          });
          break;
        case "Burggravenstraat":
          setCenter({
            lat: 51.180684,
            lng: 3.563475,
          });
          break;
        case "Dullaert":
          setCenter({
            lat: 51.178388,
            lng: 3.568814,
          });
          break;
        case "Euerardstraat":
          setCenter({
            lat: 51.17833,
            lng: 3.569791,
          });
          break;
        case "Ganzepoel":
          setCenter({
            lat: 51.176679,
            lng: 3.563147,
          });
          break;
        case "Jan Breydelstraat":
          setCenter({
            lat: 51.177038,
            lng: 3.571106,
          });
          break;
        case "Kaaistraat":
          setCenter({
            lat: 51.182041,
            lng: 3.568642,
          });
          break;
        case "Krugercenter":
          setCenter({
            lat: 51.181214,
            lng: 3.57096,
          });
          break;
        case "Pieter De Coninckstraat":
          setCenter({
            lat: 51.176513,
            lng: 3.571323,
          });
          break;
        case "Raashekke":
          setCenter({
            lat: 51.177793,
            lng: 3.563217,
          });
          break;
        case "Schuttershof":
          setCenter({
            lat: 51.183105,
            lng: 3.56332,
          });
          break;
        case "Visstraat":
          setCenter({
            lat: 51.180991,
            lng: 3.573129,
          });
          break;
        case "Zuidmoerstraat":
          setCenter({
            lat: 51.180482,
            lng: 3.569569,
          });
          break;
        case "All":
          setCenter({
            lat: 51.180523,
            lng: 3.568949,
          });
          setZoomLevel(17);
          break;
        default:
          setCenter({
            lat: 51.180523,
            lng: 3.568949,
          });
          break;
      }
    }
  }, [mapInstance, street]);

  if (!isLoaded || loadError) {
    return null;
  }

  let standplaatsMarkers;

  if (standplaatsen) {
    standplaatsMarkers = standplaatsen.map((plaats) => {
      return (
        <StandplaatsMarker
          key={plaats.Standplaats}
          plaats={plaats}
          isSelected={selectedPlaatsen.includes(plaats)}
          map={mapInstance}
          zoomLevel={zoomLevel}
          onSelect={(plaats) => {
            setSelectedPlaatsen((current) =>
              [
                ...current.filter(
                  (pl) => pl.Standplaats !== plaats.Standplaats
                ),
                plaats,
              ].sort((a, b) => {
                if (Number(a.Standplaats) < Number(b.Standplaats)) {
                  return -1;
                } else {
                  return 1;
                }
              })
            );
          }}
          onDeselect={(plaats) => {
            setSelectedPlaatsen((current) => [
              ...current.filter((pl) => pl.Standplaats !== plaats.Standplaats),
            ]);
          }}
        />
      );
    });
  }

  let content;
  if (!token) {
    content = (
      <Error>
        {localizer.pleaseLogin}
        <ToLoginButton
          onClick={() => {
            navigate("/login");
          }}
        >
          Aanmelden
        </ToLoginButton>
      </Error>
    );
  } else {
    switch (currentStep) {
      default:
      case 1:
        content = (
          <>
            <Header>{localizer.selectPlaces}</Header>
            <Divider />
            <StepDescription>
              <Line>{localizer.reserveExplanation1}</Line>
              <Line>{localizer.reserveExplanation2}</Line>
              <Line>{localizer.reserveExplanation3}</Line>
            </StepDescription>
            <SelectedPlacesWrapper>
              <SelectedTitle>{localizer.selectedPlaces}:</SelectedTitle>
              <SelectedPlaces>
                {selectedPlaatsen.map((plaats, index) => {
                  if (index < selectedPlaatsen.length - 1) {
                    return `${plaats.Standplaats}, `;
                  } else {
                    return plaats.Standplaats;
                  }
                })}
              </SelectedPlaces>
              <ConfirmButton
                onClick={() => {
                  if (selectedPlaatsen.length > 0) {
                    initPayment();
                  }
                }}
                text={localizer.next}
                enabled={selectedPlaatsen.length > 0}
              />
            </SelectedPlacesWrapper>
          </>
        );
        switch (phase) {
          default:
          case 1:
            if (!user.isPersonFromNeighbourhood) {
              if (user.placesLastYear?.length > 0) {
                content = (
                  <Error>
                    {localizer.onlyResidents}
                    {". "}
                    {localizer.onlyResidentsButLastYear}
                  </Error>
                );
              } else {
                content = (
                  <Error>
                    {localizer.onlyResidents}
                    {". "}
                    {localizer.onlyResidentsButNotLastYear}
                  </Error>
                );
              }
            }
            break;
          case 2:
            if (
              !user.placesLastYear?.length > 0 &&
              !user.isPersonFromNeighbourhood
            ) {
              content = <Error>{localizer.notLastYear}</Error>;
            }
            break;
          case 3:
            break;
        }

        break;
      case 2:
        content = (
          <>
            <Header>{localizer.confirmPlaces}</Header>
            <Divider />
            <SelectedPlacesWrapper>
              <SelectedTitle>{localizer.selectedPlaces}:</SelectedTitle>
              <SelectedPlaces>
                {selectedPlaatsen.map((plaats, index) => {
                  if (index < selectedPlaatsen.length - 1) {
                    return `${plaats.Standplaats}, `;
                  } else {
                    return plaats.Standplaats;
                  }
                })}
              </SelectedPlaces>
            </SelectedPlacesWrapper>
          </>
        );
    }
  }

  // content = (
  //   <Message>
  //     <SubTitle>
  //       Reserveren kan enkel nog maar op zondag 1 oktober tussen 4u en 7u in het
  //       station van Eeklo
  //     </SubTitle>
  //     <Text></Text>
  //     <SmallText>Voor info: 0476 46 30 92 - dirkmussche7@telenet.be</SmallText>
  //   </Message>
  // );

  return (
    <Wrapper>
      <HorizontalWrapper>
        <StepIndicator>
          {content}
          {currentStep === 2 && hash && orderId && (
            <>
              <form
                method="post"
                action="https://www.ebonline.be/mpi/authenticate"
                id="paymentForm"
                name="paymentForm"
                align="center"
                target="_self"
              >
                <input type="hidden" name="Uid" value="1362980101" />
                <input type="hidden" name="Orderid" value={orderId} />
                <input type="hidden" name="Amount" value={amount} />
                <input
                  type="hidden"
                  name="Description"
                  value={orderDescription}
                />
                <input type="hidden" name="Hash" value={hash} />
                <input type="hidden" name="Beneficiary" value="Kaaifeesten" />
                <input
                  type="hidden"
                  name="Feedbackurl"
                  value="https://kaaikenapi.www.kaaiken.be/api/kaaifeesten/completePayment"
                />
                <input type="hidden" name="Feedbacktype" value="ONLINE" />
                <input
                  type="hidden"
                  name="Redirecturl"
                  value="https://www.kaaiken.be"
                />
                <input type="hidden" name="Redirecttype" value="DIRECT" />
                <input type="hidden" name="Title" value="De Kaaifeesten" />
                <input type="hidden" name="Chname" value={name} />
                <input type="hidden" name="Chlanguage" value={getLanguage()} />
              </form>
              <ButtonWrapper>
                <CancelButton
                  type="submit"
                  name="submit"
                  text={localizer.back}
                  enabled={true}
                  onClick={() => {
                    setCurrentStep(1);
                  }}
                />
                <ConfirmButton
                  type="submit"
                  name="submit"
                  text={localizer.confirm}
                  enabled={true}
                  onClick={startPayment}
                />
              </ButtonWrapper>
            </>
          )}
        </StepIndicator>
        <MapWrapper
          initial={{ opacity: 0 }}
          animate={mapInstance ? { opacity: 1 } : { opacity: 0 }}
        >
          <GoogleMap
            id={mapId}
            center={center}
            onLoad={onMapLoad}
            options={{
              disableDoubleClickZoom: true,
            }}
            onZoomChanged={() => {
              if (mapInstance) {
                setZoomLevel(mapInstance.zoom);
              }
            }}
            mapContainerStyle={{
              height: "100%",
              width: "100%",
            }}
            zoom={zoomLevel}
          >
            {standplaatsMarkers}
          </GoogleMap>
        </MapWrapper>
      </HorizontalWrapper>
    </Wrapper>
  );
};

export default Rommelmarkt;
