import Page from "pages/Page";
import React, { useState, useEffect, useRef } from "react";

import { useAuthState } from "stores/AuthStore";
import { GetAllStandplaatsen } from "api/calls";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import localizer from "localization/localizer";

import Promo from "resources/PromoForSite.mp4";

import Plan from "resources/planRommelmarkt.png";

// Styling
import styled from "styled-components";
import { enkelBeschikbarePlaatsen } from "helpers";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  background: ${(props) => props.theme.gray100};
  border-radius: 15px;
  padding-top: 0;
  display: flex;
  flex-flow: column;
`;

const Title = styled.div`
  font-size: 30px;
  font-weight: 400;
  padding: 10px 25px;
  margin-bottom: 25px;
  width: 100%;
  text-align: center;
  border: none;
  color: white;
  background-color: rgba(29, 31, 35, 1);
  border-top-right-radius: 10px;
`;

const HorizontalWrapper = styled.div`
  display: flex;
  flex-flow: row;
  width: 100%;
  height: 90%;
  overflow: auto;
`;

const InformationWrapper = styled.div`
  flex-basis: 40%;
  //background-color: rgba(255, 0, 0, 0.1);
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
`;

const MarktWrapper = styled.div`
  flex-basis: 60%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
`;

const SubTitle = styled.div`
  font-size: 1.5rem;
`;

const DownloadButton = styled.a`
  font-size: 1.2rem;
  margin-top: 5px;
  margin-bottom: 20px;
`;

// const Text = styled.div`
//   font-size: 1.2rem;
//   max-width: 80%;
//   text-align: center;
//   margin-bottom: 0.75em;
// `;

// const SmallText = styled.div`
//   font-size: 1rem;
//   max-width: 80%;
//   text-align: center;
//   margin-bottom: 5px;
// `;

const ColumnWrapper = styled.div`
  display: flex;
  flex-flow: row;
  width: 100%;
  margin-top: 5px;
`;

const Column = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-flow: column;
  align-items: center;
`;

const Card = styled(motion.div)`
  background: white;
  border: 0.15em solid ${(props) => props.theme.primaryColor};
  border-radius: 10px;
  width: 80%;
  padding: 10px 15px;
  margin-bottom: 10px;
  display: flex;
  flex-flow: row;
  cursor: pointer;
`;

const Plaats = styled.div`
  align-self: flex-start;
`;

const Aantal = styled.div`
  margin-left: auto;
  align-self: flex-end;
`;

const TotalPlaces = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;
  font-size: 1.5rem;
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
`;

const ImagePreview = styled.video`
  position: absolute;
  top: -100%;
  bottom: -100%;
  left: -100%;
  right: -100%;
  margin: auto;
  height: 100%;
  max-width: none;
  transition: width 1s ease, height 1s ease;
`;

const RommelmarktOverview = () => {
  const navigate = useNavigate();
  const { AuthState, AuthStateDispatch } = useAuthState();
  const { token } = AuthState;

  const [Blekerij, setBlekerij] = useState();
  const [BroedersvanLiefdestraat, setBroedersvanLiefdestraat] = useState();
  const [Burggravenstraat, setBurggravenstraat] = useState();
  const [Dullaert, setDullaert] = useState();
  const [Euerardstraat, setEuerardstraat] = useState();
  const [Ganzepoel, setGanzepoel] = useState();
  //const [JanBreydelstraat, setJanBreydelstraat] = useState();
  const [Kaaistraat, setKaaistraat] = useState();
  const [Krugercenter, setKrugercenter] = useState();
  //const [PieterdeConinckstraat, setPieterdeConinckstraat] = useState();
  const [Raashekke, setRaashekke] = useState();
  //const [Schuttershof, setSchuttershof] = useState();
  const [Visstraat, setVisstraat] = useState();
  const [Zuidmoerstraat, setZuidmoerstraat] = useState();

  const [total, setTotal] = useState();
  const [reserved, setReserved] = useState();

  const videoRef = useRef();

  useEffect(() => {
    GetAllStandplaatsen().then((result) => {
      if (result.data) {
        const allePlaatsen = enkelBeschikbarePlaatsen(result.data);

        setTotal(allePlaatsen.filter((x) => x.Bezet === 0).length);
        setReserved(allePlaatsen.filter((x) => x.Bezet === 1).length);

        setBlekerij(allePlaatsen.filter((x) => x.Straat === "Blekerij"));
        setBroedersvanLiefdestraat(
          allePlaatsen.filter((x) => x.Straat === "Broeders van Liefdestraat")
        );
        setBurggravenstraat(
          allePlaatsen.filter((x) => x.Straat === "Burggravenstraat")
        );
        setDullaert(allePlaatsen.filter((x) => x.Straat === "Dullaert"));
        setEuerardstraat(
          allePlaatsen.filter((x) => x.Straat === "Euerardstraat")
        );
        setGanzepoel(allePlaatsen.filter((x) => x.Straat === "Ganzepoel"));
        // setJanBreydelstraat(
        //   allePlaatsen.filter((x) => x.Straat === "Jan Breydelstraat")
        // );
        setKaaistraat(allePlaatsen.filter((x) => x.Straat === "Kaaistraat"));
        setKrugercenter(allePlaatsen.filter((x) => x.Straat === "Kruger"));
        // setPieterdeConinckstraat(
        //   allePlaatsen.filter((x) => x.Straat === "Pieter De Coninckstraat")
        // );
        setRaashekke(allePlaatsen.filter((x) => x.Straat === "Raashekke"));
        // setSchuttershof(
        //   allePlaatsen.filter((x) => x.Straat === "Schuttershof")
        // );
        setVisstraat(allePlaatsen.filter((x) => x.Straat === "Visstraat"));
        setZuidmoerstraat(
          allePlaatsen.filter((x) => x.Straat === "Zuidmoerstraat")
        );
      }
    });
  }, [token]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 0.9;
      videoRef.current.play();
    }
  }, [videoRef]);

  const onStartReserve = (street) => {
    AuthStateDispatch({
      type: "streetChosen",
      payload: street,
    });

    navigate(`/reserve`);
  };

  return (
    <Page>
      <Wrapper>
        <HorizontalWrapper>
          <InformationWrapper>
            <ImageWrapper>
              <ImagePreview
                onContextMenu={(e) => {
                  e.preventDefault();
                }}
                ref={videoRef}
                src={Promo}
                type="video/mp4"
                muted
                autoplay
                loop
              ></ImagePreview>
            </ImageWrapper>
          </InformationWrapper>
          <MarktWrapper>
            <Title>{localizer.marktTitle}</Title>
            <SubTitle>{localizer.marktExplanation}</SubTitle>
            <DownloadButton href={Plan} download="plan.png">
              {localizer.marktDownloadPlan}
            </DownloadButton>
            {/* <Text> €12/6m - Wijk 't Kaaiken</Text> */}
            {/* <Text>
              Indien problemen met online reservatie: €14/plaats storten op
              rekening BE23 8901 7408 2291 op naam van Kaaiken met in de
              mededeling de standplaatsnummer(s)
            </Text> */}
            {/* <SubTitle>
              Reserveren kan enkel nog maar op zondag 1 oktober tussen 4u en 7u
              in het station van Eeklo
            </SubTitle>
            <Text></Text>
            <SmallText>
              Voor info: 0476 46 30 92 - dirkmussche7@telenet.be
            </SmallText> */}
            {/* <SmallText>
              Voor technische ondersteuning: quentin.mussche@gmail.com
            </SmallText> */}
            <ColumnWrapper>
              <Column>
                <Card
                  whileHover={{ scale: 1.05 }}
                  onClick={() => {
                    onStartReserve("Blekerij");
                  }}
                >
                  <Plaats>Blekerij</Plaats>

                  <Aantal>
                    {localizer.available}:
                    {` ${Blekerij?.filter((x) => x.Bezet === 0).length}`}
                  </Aantal>
                </Card>
                <Card
                  whileHover={{ scale: 1.05 }}
                  onClick={() => {
                    onStartReserve("Broeders van Liefdestraat");
                  }}
                >
                  <Plaats>Broeders van Liefdestraat</Plaats>
                  <Aantal>
                    {
                      BroedersvanLiefdestraat?.filter((x) => x.Bezet === 0)
                        .length
                    }
                  </Aantal>
                </Card>
                <Card
                  whileHover={{ scale: 1.05 }}
                  onClick={() => {
                    onStartReserve("Burggravenstraat");
                  }}
                >
                  <Plaats>Burggravenstraat</Plaats>
                  <Aantal>
                    {Burggravenstraat?.filter((x) => x.Bezet === 0).length}
                  </Aantal>
                </Card>
                <Card
                  whileHover={{ scale: 1.05 }}
                  onClick={() => {
                    onStartReserve("Dullaert");
                  }}
                >
                  <Plaats>Dullaert</Plaats>
                  <Aantal>
                    {Dullaert?.filter((x) => x.Bezet === 0).length}
                  </Aantal>
                </Card>
                <Card
                  whileHover={{ scale: 1.05 }}
                  onClick={() => {
                    onStartReserve("Euerardstraat");
                  }}
                >
                  <Plaats>Euerardstraat</Plaats>
                  <Aantal>
                    {Euerardstraat?.filter((x) => x.Bezet === 0).length}
                  </Aantal>
                </Card>
                <Card
                  whileHover={{ scale: 1.05 }}
                  onClick={() => {
                    onStartReserve("Ganzepoel");
                  }}
                >
                  <Plaats>Ganzepoel</Plaats>
                  <Aantal>
                    {Ganzepoel?.filter((x) => x.Bezet === 0).length}
                  </Aantal>
                </Card>
                {/* <Card
                  whileHover={{ scale: 1.05 }}
                  onClick={() => {
                    onStartReserve("Jan Breydelstraat");
                  }}
                >
                  <Plaats>Jan Breydelstraat</Plaats>
                  <Aantal>
                    {JanBreydelstraat?.filter((x) => x.Bezet === 0).length}
                  </Aantal>
                </Card> */}
                <Card
                  whileHover={{ scale: 1.05 }}
                  onClick={() => {
                    onStartReserve("All");
                  }}
                >
                  <Plaats>{localizer.entireMap}</Plaats>
                  <Aantal>{total}</Aantal>
                </Card>
              </Column>
              <Column>
                <Card
                  whileHover={{ scale: 1.05 }}
                  onClick={() => {
                    onStartReserve("Kaaistraat");
                  }}
                >
                  <Plaats>Kaaistraat</Plaats>
                  <Aantal>
                    {Kaaistraat?.filter((x) => x.Bezet === 0).length}
                  </Aantal>
                </Card>
                <Card
                  whileHover={{ scale: 1.05 }}
                  onClick={() => {
                    onStartReserve("Krugercenter");
                  }}
                >
                  <Plaats>Krugercenter</Plaats>
                  <Aantal>
                    {Krugercenter?.filter((x) => x.Bezet === 0).length}
                  </Aantal>
                </Card>
                {/* <Card
                  whileHover={{ scale: 1.05 }}
                  onClick={() => {
                    onStartReserve("Pieter de Coninckstraat");
                  }}
                >
                  <Plaats>Pieter de Coninckstraat</Plaats>
                  <Aantal>
                    {PieterdeConinckstraat?.filter((x) => x.Bezet === 0).length}
                  </Aantal>
                </Card> */}
                <Card
                  whileHover={{ scale: 1.05 }}
                  onClick={() => {
                    onStartReserve("Raashekke");
                  }}
                >
                  <Plaats>Raashekke</Plaats>
                  <Aantal>
                    {Raashekke?.filter((x) => x.Bezet === 0).length}
                  </Aantal>
                </Card>
                {/* <Card
                  whileHover={{ scale: 1.05 }}
                  onClick={() => {
                    onStartReserve("Schuttershof");
                  }}
                >
                  <Plaats>Schuttershof</Plaats>
                  <Aantal>
                    {Schuttershof?.filter((x) => x.Bezet === 0).length}
                  </Aantal>
                </Card> */}
                <Card
                  whileHover={{ scale: 1.05 }}
                  onClick={() => {
                    onStartReserve("Visstraat");
                  }}
                >
                  <Plaats>Visstraat</Plaats>
                  <Aantal>
                    {Visstraat?.filter((x) => x.Bezet === 0).length}
                  </Aantal>
                </Card>
                <Card
                  whileHover={{ scale: 1.05 }}
                  onClick={() => {
                    onStartReserve("Zuidmoerstraat");
                  }}
                >
                  <Plaats>Zuidmoerstraat</Plaats>
                  <Aantal>
                    {Zuidmoerstraat?.filter((x) => x.Bezet === 0).length}
                  </Aantal>
                </Card>
              </Column>
            </ColumnWrapper>
            <TotalPlaces>
              {localizer.alreadyReserved}: {reserved} -{" "}
              {localizer.availableStands}: {total}
            </TotalPlaces>
          </MarktWrapper>
        </HorizontalWrapper>
      </Wrapper>
    </Page>
  );
};

export default RommelmarktOverview;
